import * as THREE from "three";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { gsap } from "gsap";
import { noise } from "./perlin";
import hdri from "../assets/images/hdri/empty_warehouse_01_4k.hdr";
import normal from "../assets/images/normal/normal.jpg";

const threeModel = () => {
  // VARIABLES
  const settings = {
    speed: 0.2,
    density: 1.5,
    strength: 0.2,
  };

  const params = {
    roughness: 0.2,
    metalness: 0,
    transmission: 0.5,
    thickness: 1.0,
    debug: false,
  };

  // SCENE
  const scene = new THREE.Scene();

  // CAMERA
  const camera = new THREE.PerspectiveCamera(
    45,
    window.innerWidth / window.innerHeight,
    0.5,
    1000
  );
  camera.position.z = 200;

  // LIGHT
  const light = new THREE.DirectionalLight(0xfff0dd, 0.3);
  light.position.set(5, 5, 1000);
  scene.add(light);

  const fillLight = new THREE.SpotLight(0x03fcad, 0.3, 400, 1.05);
  fillLight.position.set(0, 10, 1000);
  scene.add(fillLight);

  const rimLight = new THREE.SpotLight(0xf2fcff, 0.2, 400, 20);
  rimLight.position.set(0, 0, 1000);
  scene.add(rimLight);

  const keylight = new THREE.SpotLight(0x03fcf8, 0.3, 400, 1.05);
  keylight.position.set(0, -300, 1000);
  scene.add(keylight);

  // RENDER
  const renderer = new THREE.WebGLRenderer({ alpha: true });
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.setPixelRatio(window.devicePixelRatio || 1);
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = THREE.PCFSoftShadowMap;
  document.body.appendChild(renderer.domElement);

  // CANVAS ELEMENT
  const canvas = document.getElementsByTagName("canvas").item(0);
  let mouse = new THREE.Vector2(0.8, 0.5);
  let width = canvas.offsetWidth;
  let height = canvas.offsetHeight;

  // CAMERA INTERACTION
  let controls = new OrbitControls(camera, renderer.domElement);
  controls.minDistance = 10;
  controls.maxDistance = 500;

  // GEOMETRY
  let geometry = new THREE.IcosahedronBufferGeometry(50, 50);
  geometry.setAttribute(
    "basePosition",
    new THREE.BufferAttribute().copy(geometry.attributes.position)
  );

  // MATERIAL

  const hdrEquirect = new RGBELoader().load(hdri, () => {
    hdrEquirect.mapping = THREE.EquirectangularReflectionMapping;
  });

  const textureLoader = new THREE.TextureLoader();
  const normalMapTexture = textureLoader.load(normal);
  normalMapTexture.wrapS = THREE.RepeatWrapping;
  normalMapTexture.wrapT = THREE.RepeatWrapping;

  const material = new THREE.MeshPhysicalMaterial({
    roughness: params.roughness,
    transmission: params.transmission,
    thickness: params.thickness,
    envMap: hdrEquirect,
    normalMap: normalMapTexture,
    clearcoatNormalMap: normalMapTexture,
  });

  // MESH
  const sphereMesh = new THREE.Mesh(geometry, material);
  scene.add(sphereMesh);

  // UPDATE MESH
  const setNewPoints = (a) => {
    let basePositionAttribute = geometry.getAttribute("basePosition");
    const positionAttribute = geometry.getAttribute("position");
    let newPositionAttribute = [];
    const vertex = new THREE.Vector3();

    for (
      let vertexIndex = 0;
      vertexIndex < positionAttribute.count;
      vertexIndex++
    ) {
      vertex.fromBufferAttribute(basePositionAttribute, vertexIndex);
      var perlin = noise.simplex3(
        vertex.x * 0.006 + (a / 2) * 0.0002,
        vertex.y * 0.006 + (a / 2) * 1 * 0.0003,
        vertex.z * 0.006
      );
      var ratio = perlin * 0.4 * (mouse.y + 0.1) + 0.8;
      vertex.multiplyScalar(ratio);

      positionAttribute.setXYZ(vertexIndex, vertex.x, vertex.y, vertex.z);
    }

    geometry.attributes.position.needsUpdate = true; // required after the first render
    geometry.computeBoundingSphere();
  };

  const render = (a) => {
    setNewPoints(a);
    renderer.render(scene, camera);
    requestAnimationFrame(render);
  };

  const onMouseMove = (e) => {
    gsap.to(mouse, {
      y: e.clientY / height,
      x: e.clientX / width,
      ease: "power",
    });
  };

  const resize = () => {
    let width = window.innerWidth;
    let height = window.innerHeight;

    camera.aspect = width / height;
    renderer.setSize(width, height);

    camera.updateProjectionMatrix();
  };

  requestAnimationFrame(render);

  // EVENT LISTENERS
  window.addEventListener("resize", () => {
    resize();
  });
  window.addEventListener("mousemove", onMouseMove);
};

export default threeModel;
