import React from "react";
import { images } from "../../assets/json/images";
import { copy } from "../../assets/json/copy";
import "./socialMediaLinks.scss";

const SocialMediaLinks = () => {
  const link = copy.socialMediaLinks;

  return (
    <footer>
      <ul>
        <li className="list-item">
          <a
            className="list-item__link behance"
            href={link.behance}
            target="_blank"
          >
            <img className="list-item__image" src={images.socials.behance} />
          </a>
        </li>
        <li className="list-item">
          <a
            className="list-item__link linkedin"
            href={link.linkedin}
            target="_blank"
          >
            <img className="list-item__image" src={images.socials.linkedin} />
          </a>
        </li>
        <li className="list-item">
          <a
            className="list-item__link email"
            href={link.email}
            target="_blank"
          >
            <img className="list-item__image" src={images.socials.email} />
          </a>
        </li>
      </ul>
    </footer>
  );
};

export default SocialMediaLinks;
