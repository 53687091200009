export const copy = {
  title: "Huis Heeren",
  body: [
    "Huis Heeren is an alias for Louis Heeren, creative technologist and designer. As graduate in Digital Design and Development Louis is schooled in multiple aspects of the digital landscape. During his education, he learned about User Research, UI and UX Design, Motion Design and Front-end Development.",
    "With the in-depth knowledge sharing, he hopes to grow and investigate better the field of Sound Design and how this could be applied in Human-Computer Interaction.",
  ],
  socialMediaLinks: {
    behance: "https://www.behance.net/louisoccida6f8",
    linkedin: "https://www.linkedin.com/in/louis-heeren/",
    email: "mailto:huisheeren@gmail.com",
  },
};
