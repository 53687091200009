import React, { useEffect } from "react";
import "./home.scss";
import { headerAnimation } from "../../_utils/_animations";

const Home = () => {
  const title = React.createRef();
  const subtitle = React.createRef();

  useEffect(() => {
    headerAnimation(title, subtitle);
  });

  return (
    <header>
      <h1 ref={title}>HUIS HEEREN</h1>
      <h2 ref={subtitle}>CREATIVE STUDIO</h2>
    </header>
  );
};

export default Home;
