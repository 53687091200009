import React, { useEffect } from "react";
import "./mouse.scss";

const Mouse = () => {
  useEffect(() => {
    const cursor = document.querySelector("div.mouse__big-circle");
    const arrayLinks = document.getElementsByTagName("a");

    const mouseMove = (x, y) => {
      cursor.style.left = x + "px";
      cursor.style.top = y + "px";
    };

    document.addEventListener("mousemove", (e) => {
      mouseMove(e.pageX, e.pageY);
    });

    Array.from(arrayLinks).forEach((link) => {
      link.addEventListener("mouseover", () => {
        cursor.style.width = "70px";
        cursor.style.height = "70px";
      });

      link.addEventListener("mouseout", () => {
        cursor.style.width = "15px";
        cursor.style.height = "15px";
      });
    });
  });

  return <div className="mouse__big-circle"></div>;
};

export default Mouse;
