import Home from "./Pages/Home/Home";
import Mouse from "./Components/Mouse/Mouse";
import SocialMediaLinks from "./Components/SocialMediaLinks/SocialMediaLinks";
import threeModel from "./_utils/_threeModel";
import { useEffect } from "react";
import { blobAnimation } from "./_utils/_animations";

const App = () => {
  useEffect(() => {
    setTimeout(() => {
      threeModel();
      blobAnimation();
    }, 2000);
  });

  return (
    <div className="App">
      {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ) ? (
        ""
      ) : (
        <Mouse />
      )}
      <Home />
      <SocialMediaLinks />
    </div>
  );
};

export default App;
