import { gsap } from "gsap";

export const headerAnimation = (title, subtitle) => {
  const tl = gsap.timeline();
  tl.to(title.current, { opacity: 1, duration: 2, delay: 1 });
  tl.to(subtitle.current, { opacity: 1, duration: 1 }, "-=1");
  setTimeout(socialMediaLinksAnimation, 2000);
};

export const socialMediaLinksAnimation = () => {
  const tl = gsap.timeline();
  const links = document.querySelectorAll(".list-item__link");
  links.forEach((link, i) => {
    gsap.fromTo(
      link,
      { opacity: 0, y: 20 },
      {
        opacity: 0.6,
        y: 0,
        duration: 0.5,
        ease: "power",
        delay: i * 0.5,
      }
    );
    gsap.to(link, {
      paddingLeft: 15,
      paddingRight: 15,
      duration: 0.2,
      ease: "power3",
      delay: 2,
    });
  });
};

export const blobAnimation = () => {
  gsap.fromTo("canvas", { opacity: 0 }, { opacity: 1, duration: 1, delay: 5 });
};
