import behance from "../images/socials/behance.svg";
import linkedin from "../images/socials/linkedin.svg";
import email from "../images/socials/mail.svg";
import behance_before from "../images/socials/behance_before.svg";
import linkedin_before from "../images/socials/linkedin_before.svg";
import email_before from "../images/socials/email_before.svg";

export const images = {
  socials: {
    behance: behance,
    linkedin: linkedin,
    email: email,
    behance_before: behance_before,
    linkedin_before: linkedin_before,
    email_before: email_before,
  },
};
